<template>
  <div>
    <b-row>
      <b-col>
        <b-card>

          <b-row v-if="otpMessage !== null">
              <b-col>
                  <b-alert :show="true" dismissible :variant="otpColour">{{
                      otpMessage
                  }}</b-alert>
              </b-col>
          </b-row>

          <b-row>
              <b-col>
                  <h4>All Completed Checklists</h4>
              </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
                <label>Type</label>
                <b-form-select v-model="search.typeId" :disabled="state === 'loading'" @change="getCheckLists()">
                    <b-form-select-option v-for="(item, index) in checklistTypes" :key="index" :value="item.id">{{item.description}}</b-form-select-option>
                </b-form-select>
            </b-col>
            <b-col cols="4">
              <label>Checklist Description</label>
              <!-- <b-form-input v-model="search.description" :disabled="search.typeId === null"></b-form-input> -->
              <b-form-select v-model="search.description" :disabled="search.typeId === null">
                    <b-form-select-option v-for="(item, index) in checkkistNames" :key="index" :value="item">{{item}}</b-form-select-option>
                </b-form-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <label>Date Completed From</label>
              <b-form-datepicker v-model="search.dateCreatedFrom"></b-form-datepicker>
            </b-col>
            <b-col cols="4">
              <label>Date Completed To</label>
              <b-form-datepicker v-model="search.dateCreatedTo"></b-form-datepicker>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <label>Company</label>
              <b-form-select v-model="search.nextecCompanyId" :disabled="state === 'loading'" @change="companySelected()">
                <b-form-select-option v-for="(item, index) in companyList" :key="index" :value="item.key">{{item.value}}</b-form-select-option>
              </b-form-select>
            </b-col>
            <!-- <b-col cols="4">
              <label>Region</label>
              <b-form-select v-model="search.nextecRegionId" :disabled="state === 'loading'">
                <b-form-select-option v-for="(item, index) in regionList" :key="index" :value="item.key">{{item.value}}</b-form-select-option>
              </b-form-select>
            </b-col> -->
            <b-col cols="4">
              <label>Supervisor</label>
              <b-input-group>
                    <b-form-input v-model="search.supervisorname" :disabled="true"></b-form-input>
                    <b-input-group-append>
                        <b-button size="sm"  text="Button" variant="secondary" @click="openAddUserModal()">Search</b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-col>
            
            
          </b-row>
          <hr class="mx-3" />
          <b-col class="text-right">
            <b-button variant="red" class="ml-2" squared @click="clearFilter()">Cancel</b-button>
            <b-button variant="primary" class="ml-2" squared @click="doSearchChecklist()" >Search</b-button>
          </b-col>
          <b-row>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <b-card>
          <b-row>
            <b-col class="text-right">
              <b-button variant="primary" class="ml-2" squared @click="doDownloadCompletedChecklists()" >
                <b-spinner v-if="isDownloading" small></b-spinner>
              Download Results</b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              
              <b-table striped hover
                      :items="tableData.dataSource"
                      :fields="tableData.tableColumns"
                      :busy="tableData.isLoading"
                      @row-clicked="openChecklist"
                      :per-page="tableData.resultsPerPage"
                      id="stockTable"
                      :current-page="tableData.currentPage" sort-icon-left>

                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                  </div>
                </template>

                <template #cell(completedDate)="row">
                  <b-row align-v="center">
                    <span class="mr-auto">{{row.item.completedDate | dateTimeFilter}}</span>
                  </b-row>
                </template>

                <template #cell(actions)="row">
                  <b-row @click="openChecklist(row.item)" align-v="center" align-h="end">
                    <b-button size="sm" class="btn-icon">
                      <b-icon-chevron-right></b-icon-chevron-right>
                    </b-button>
                  </b-row>
                  
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row align-h="center" >
            <b-pagination
                v-model="tableData.currentPage"
                :total-rows="rows"
                :per-page="tableData.resultsPerPage"
                aria-controls="stockTable"
            ></b-pagination>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    
    <b-modal id="show-results-modal" hide-footer size="xl" no-header @close="closeResultsModal()">
        <b-row>
            <b-col>
            </b-col>
        </b-row>
    </b-modal>

    <b-modal id="search-user-modal" size="xl" hide-footer no-header @close="closeSearchUserModal">
            
      <b-row>
          <b-col cols="4">
              <label>Name</label>
              <b-form-input v-model="user.name"></b-form-input>
          </b-col>
          <b-col cols="4">
              <label>Surname</label>
              <b-form-input v-model="user.surname"></b-form-input>
          </b-col>
          <b-col cols="4">
              <label>Email</label>
              <b-form-input v-model="user.email"></b-form-input>
          </b-col>
      </b-row>
      <hr class="mx-3">
      <b-row>
          <b-col class="text-right">
              <b-button variant="red" class="ml-2" squared @click="clearUsersFilter" >Clear</b-button>
              <b-button variant="primary" squared @click="doSearchUsers" class="ml-2">Search</b-button>
          </b-col>
      </b-row>
      
      <b-row>
        <b-col class="12">
            <b-table striped hover 
                    :items="tableDataUsers.dataSource" 
                    :fields="tableDataUsers.tableColumns"
                    :busy="tableDataUsers.isLoading" 
                    @row-clicked="selectUserFromList"
                    :per-page="tableDataUsers.resultsPerPage"
                    id="userSearch"
                    :current-page="tableDataUsers.currentPage">

                <template #table-busy>
                    <div class="text-center my-2">
                        <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                    </div>
                </template>

                <template #cell(actions)="row">
                    <b-row align-v="center" align-h="end">
                        <b-button @click="chooseUser(row.item)" size="sm" class="btn-icon">
                        <b-icon-chevron-right></b-icon-chevron-right>
                        </b-button>
                    </b-row>
                </template>
            </b-table>
        </b-col>
      </b-row>
    
      <b-row align-h="center" >
          <b-pagination
              v-model="tableDataUsers.currentPage"
              :total-rows="userRows"
              :per-page="tableDataUsers.resultsPerPage"
              aria-controls="userSearch"
          ></b-pagination>
      </b-row>
      <b-row>
          <b-col class="text-right">
              <b-button variant="red" class="mr-2" squared @click="closeSearchUserModal">Cancel</b-button>
          </b-col>
      </b-row>
    </b-modal>
    
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
export default {
  name: "allCompletedChecklist",
  data: () => ({
    otpMessage:null,
    otpColour:null,
    state: 'loading',
    search: {
      description: null,
      dateCreatedFrom: null,
      dateCreatedTo: null,
      typeId: null,
      supervisorid: null,
      supervisorname: null,
      nextecCompanyId: null,
      nextecRegionId: null
    },
    user: {
        name: null,
        surname: null,
        email: null,
        roleID: 0
    },
    tableDataUsers: {
        resultsPerPage: 10,
        currentPage: 1,
        dataSource: [],
        isLoading: true,
        tableColumns: [
            {
            label: 'Name',
            key: 'name',
            sortable: true,
            tdClass: '',
            },
            {
            label: 'Surname',
            key: 'surname',
            sortable: true,
            tdClass: '',
            },
            {
            label: 'Email',
            key: 'email',
            sortable: true,
            tdClass: '',
            },
            {
            label: 'Contact Number',
            key: 'contactNumber',
            sortable: true,
            tdClass: '',
            },
            {
            label: '',
            key: 'actions',
            sortable: false,
            tdClass: ''
            }
        ]
    },

    isDownloading: false,
    tableData: {
      resultsPerPage: 10,
      currentPage: 1,
      dataSource: [],
      isLoading: true,
      tableColumns: [
        {
          label: 'Checklist Description',
          key: 'checkListName',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Date Completed',
          key: 'completedDate',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Completed By',
          key: 'completedByUser',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Type',
          key: 'type',
          sortable: true,
          tdClass: '',
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: ''
        }
      ]
    },
    userRows: null,
    checklistTypes: [],
    checkkistNames:[],
    companyList: [],
    regionList: []
  }),
  created() {
    
    this.setBreadcrumb([
        {
            text: 'Checklist'
        },
        {
            text: 'Search',
            active: true
        },
    ])
    
    this.getChecklistTypes()
    .then((res) => {
        this.doSearchChecklist()
        this.checklistTypes = res.data
        this.state = 'show'
    })
    .catch(() => {
        this.state = 'show'
    })

    this.getCompanyList()
    .then((res) => {
        //this.doSearchChecklist()
        this.companyList = res.data
    })
    .catch(() => {
        this.state = 'show'
    })

  },
  methods: {
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),
    ...mapActions(['searchAllCompletedChecklists', 'searchAllTicketCompletedChecklists', 'downloadAllCompletedChecklists', 
                'getCompanyList', 'getRegionListForACL', 'searchSupervisorsRequest', 'getChecklistTypes', 'searchChecklists']),
    openChecklist(rowItem) {
      this.$store.commit('setCompletedChecklist', rowItem)
      this.$router.push({path: '/checklist/completedView'})
    },

    clearMessage() {
      setTimeout(() => {
          this.otpMessage = null;
      }, 10000);
    },
    
    showMessage(msgtype, message){
      this.otpColour = msgtype; //"danger" or "success";
      this.otpMessage = message;

    },

    clearFilter(){
      this.clearMessage()
      this.search = {
        description: null,
        dateCreatedFrom: null,
        dateCreatedTo: null,
        typeId: null,
        nextecRegionId:null,
        nextecCompanyId:null,
        supervisorid:null
      }
      this.doSearchChecklist()
    },
    closeResultsModal(){
      this.$root.$emit('bv::hide::modal', 'show-results-modal', '#btnShow')
    },
    
    companySelected(){
      this.search.nextecRegionId = null
      this.search.supervisorid = null
      this.search.supervisorname = null

      this.user = {
        name: null,
        surname: null,
        email: null
      }
      // this.$store.commit('setGetRegionRequest',this.search.nextecCompanyId);
      // this.getRegionListForACL()
      //   .then((res) => {
      //     this.regionList = res.data
      //   })
    },

    selectUserFromList(rowitem)
    {
      this.search.supervisorid = rowitem.userId
      this.search.supervisorname = rowitem.name + ' ' + rowitem.surname
      this.closeSearchUserModal()
    },

    doDownloadCompletedChecklists(){
      this.clearMessage()
      let request = []
      if(this.search.description !== null){
          request.push({"key":"description","value": this.search.description})
      }
      if(this.search.dateCreatedFrom !== null){
          request.push({"key":"completedDateFrom","value": this.search.dateCreatedFrom})
      }
      if(this.search.dateCreatedTo !== null){
          request.push({"key":"completedDateTo","value": this.search.dateCreatedTo})
      }
      if(this.search.typeId !== null){
          request.push({"key":"fkcheckListTypeid","value": this.search.typeId})
      }
      request.push({"key":"completed","value": true})
      if(this.search.nextecCompanyId !== null){
          request.push({"key":"fkNextecCompanyId","value": this.search.nextecCompanyId})
      }
      if(this.search.nextecRegionId !== null){
          request.push({"key":"fkNextecRegionId","value": this.search.nextecRegionId})
      }
      if(this.search.supervisorid !== null){
          request.push({"key":"supervisorid","value": this.search.supervisorid})
      }
      this.$store.commit('setChecklistSearchRequest', request)
      
      console.log('doDownloadCompletedChecklists', request)
      this.isDownloading = true

      this.downloadAllCompletedChecklists()
      .then((res) => {
        if (res != null && res.data != '')
        {
          const linkSource = `data:${res.data.contentType};base64,${res.data.base64Data}`;
          const downloadLink = document.createElement("a");

          downloadLink.href = linkSource;
          downloadLink.download = res.data.fileName;
          downloadLink.click();
          this.isDownloading = false
        }
        else{
          console.log('nothing to download')
          this.showMessage("danger","No data found to download")
          this.isDownloading = false
        }
      })
    },

    getCheckLists(){
      this.clearMessage()
      //Checklist Description
      let request = []
      this.checkkistNames=[]
      
      request.push({"key":"fkCheckListTypeId","value": this.search.typeId})
      this.$store.commit('setChecklistSearchRequest', request)
      this.searchChecklists()
      .then((res) => {
          res.data.forEach(element => {
            this.checkkistNames.push(element.description)
          });
          console.log('this.checkkistNames',this.checkkistNames)
      })
      .catch(() => {
          this.tableData.isLoading = false
      })
    },

    doSearchChecklist(){
      this.tableData.isLoading = true
      
      let request = []
      if(this.search.typeId !== null){
          request.push({"key":"fkcheckListTypeid","value": this.search.typeId})
      }
      if(this.search.description !== null){
          request.push({"key":"description","value": this.search.description})
      }
      if(this.search.dateCreatedFrom !== null){
          request.push({"key":"completedDateFrom","value": this.search.dateCreatedFrom})
      }
      if(this.search.dateCreatedTo !== null){
          request.push({"key":"completedDateTo","value": this.search.dateCreatedTo})
      }

      request.push({"key":"completed","value": true})
      if(this.search.nextecCompanyId !== null){
          request.push({"key":"fkNextecCompanyId","value": this.search.nextecCompanyId})
      }
      if(this.search.nextecRegionId !== null){
          request.push({"key":"fkNextecRegionId","value": this.search.nextecRegionId})
      }
      if(this.search.supervisorid !== null){
          request.push({"key":"supervisorid","value": this.search.supervisorid})
      }

      this.$store.commit('setChecklistSearchRequest', request)
      this.searchAllCompletedChecklists()
      .then((res) => {
        
        //console.log("userchecklists", res.data);
          this.tableData.dataSource = res.data
          //this.tableData.isLoading = false
          this.searchAllTicketCompletedChecklists()
          .then((res) =>{
           //console.log("tickchecklists", res.data);
            this.tableData.dataSource =  this.tableData.dataSource.concat(res.data)
            this.tableData.isLoading = false
          })
          .catch(()=>{
            this.tableData.isLoading = false
          })
      })
      .catch(() => {
          this.tableData.isLoading = false
      })
    },

    doSearchUsers(){
      this.tableDataUsers.isLoading = true

      let request = []
      
      if (this.user.name != null) {
          request.push({'key': 'name', 'value': this.user.name})
      }
      if (this.user.surname != null) {
          request.push({'key': 'surname', 'value': this.user.surname})
      }
      if (this.user.email != null) {
          request.push({'key': 'email', 'value': this.user.email})
      }
      // // if (this.user.roleId != null) {
      // //     request.push({'key': 'fkroleId', 'value': 2})
      // // }
      
      if (this.search.nextecRegionId == null){
        request.push({'key': 'regionid', 'value': 0})
      }
      else{
        request.push({'key': 'regionid', 'value': this.search.nextecRegionId})
      }

      if (this.search.nextecCompanyId == null){
        request.push({'key': 'officeid', 'value': 0})
      }
      else{
        request.push({'key': 'officeid', 'value': this.search.nextecCompanyId})
      }
  
      this.$store.commit('setUserSearchRequest', request)
      this.searchSupervisorsRequest()
      .then((request) => {
        this.tableDataUsers.dataSource = request.data
        this.tableDataUsers.isLoading = false
        this.userRows = this.tableDataUsers.dataSource.length
        this.state  = 'initialize'
      })
      .catch( () => {
        this.tableDataUsers.isLoading = false
      })
    },

    clearUsersFilter(){
      this.user = {
        name: null,
        surname: null,
        email: null
      }
    },
    openAddUserModal(){
        this.$bvModal.show('search-user-modal')
        this.user.roleId = 2
        this.doSearchUsers()
    },
    closeSearchUserModal(){
        this.$bvModal.hide('search-user-modal')
    }

  },
  computed: {
    rows() {
      return this.tableData.dataSource.length
    },
  },
}
</script>

<style scoped>

</style>